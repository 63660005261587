import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import {
	CurrentRouteInfoSymbol,
	FeatureStateSymbol,
	IPageDidMountHandler,
	PageFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { AutoDisplayLightboxPageConfig, AutoDisplayLightboxFeatureState } from './types'
import { name } from './symbols'
import { IPopups, PopupsSymbol } from 'feature-popups'
import { IFeatureState } from 'thunderbolt-feature-state'
import { ICurrentRouteInfo } from 'feature-router'

const autoDisplayLightboxFactory = (
	{ triggerPageId, popupId, delay }: AutoDisplayLightboxPageConfig,
	featureState: IFeatureState<AutoDisplayLightboxFeatureState>,
	currentRouteInfo: ICurrentRouteInfo,
	popupsFeature?: IPopups
): IPageDidMountHandler => {
	return {
		pageDidMount() {
			if (!popupsFeature) {
				return
			}
			const popupsOpened = featureState.get()
			const isTargetPopupPage = popupsFeature.isPopupPage(popupId)

			if (isTargetPopupPage && (!popupsOpened || !popupsOpened[popupId])) {
				setTimeout(() => {
					const currentPageId = currentRouteInfo.getCurrentRouteInfo()?.pageId
					const isTriggerPageCurrentPage = triggerPageId === currentPageId
					if (isTriggerPageCurrentPage || triggerPageId === 'masterPage') {
						popupsFeature.openPopupPage(popupId)
						featureState.update((state) => ({ ...state, [popupId]: true }))
					}
				}, delay * 1000)
			}
		},
	}
}

export const AutoDisplayLightbox = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		named(FeatureStateSymbol, name),
		CurrentRouteInfoSymbol,
		optional(PopupsSymbol),
	],
	autoDisplayLightboxFactory
)
